import React from 'react'

import * as NextRouter from 'next/router'
import { getGeoIpData } from '../utils/network'
import utils from '../utils'

const IS_CAMPAIGN_ACTIVE = false

function Redirection({ utmParams }) {
  const router = NextRouter.useRouter()

  React.useEffect(() => {
    const navigateToGeoIp = async () => {
      const { ip, locale, isoCode } = await getGeoIpData()

      const query = {
        data: JSON.stringify({
          ip,
          isoCode,
        }),
      }

      const pathname = IS_CAMPAIGN_ACTIVE ? `/[locale]` : `/[locale]/closed`
      const pathAs = IS_CAMPAIGN_ACTIVE ? `/${locale}` : `/${locale}/closed`
      router.push(
        {
          pathname,
          query,
        },
        `${pathAs}` + utils.getQueryString(utmParams),
      )
    }

    navigateToGeoIp()
  })

  return <></>
}
export default Redirection
