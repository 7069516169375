import publicIP from 'public-ip'
import API from '../repository/repoAPI'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

async function getPublicIP() {
  return process.env.NODE_ENV == 'development'
    ? 'localhost'
    : await publicIP.v4()
}

const getLocaleFromIp = (geoIp) => {
  return publicRuntimeConfig.ISOCODEMAPConfig[geoIp?.isoCode]
    ? publicRuntimeConfig.ISOCODEMAPConfig[geoIp?.isoCode]
    : publicRuntimeConfig.ISOCODEMAPConfig.GB
}

const getGeoIpData = async () => {
  const ip = await getPublicIP()
  const response = await API.getGeoIP('UI')({ query: { ip } })
  const geoip = response.data
  const locale = getLocaleFromIp(geoip)

  return {
    ip,
    locale,
    isoCode: geoip.isoCode,
  }
}

export { getPublicIP, getGeoIpData }
