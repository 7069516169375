import * as Infra from '../infra'

export default {
  getLayout,
  getForms,
  getGeoIP,
  postSurvey,
  postContact,
}

function postSurvey(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/survey', params)
}
function postContact(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/contact', params)
}

function getLayout(domain = 'UI') {
  return (params) => Infra.API()(domain).get('/layout', params)
}

function getForms(domain = 'UI') {
  return (params) => Infra.API()(domain).get('/forms', params)
}

function getGeoIP(domain = 'UI') {
  return (params) =>
    domain === 'UI'
      ? Infra.API('')(domain).get('/geoip', params)
      : Infra.API('geo-ip')(domain).get('/', params)
}
